import Tooltip from '@mui/material/Tooltip'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import mixpanel from 'mixpanel-browser'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { MIXPANEL_ENABLED, THEME } from 'src/config/config'
import { useAutosuggestLazyQuery } from 'src/graphql/generated/hooks'

import { ThemeOnly } from '../ThemeOnly'
import { CategoryAutosuggestItem } from './Dropdown/CategoryAutosuggestItem'
import { CompanyAutosuggestItem } from './Dropdown/CompanyAutosuggestItem'
import { EventAutosuggestItem } from './Dropdown/EventAutosuggestItem'
import { ProductAutosuggestItem } from './Dropdown/ProductAutosuggestItem'

export interface SearchboxProps {
  className?: string
}

export const Searchbox: React.FC<SearchboxProps> = ({ className = '', ...props }) => {
  const [searchText, setSearchText] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const [searchFocused, setSearchFocused] = useState(false)
  const router = useRouter()

  const [autosuggest, { data }] = useAutosuggestLazyQuery()

  const onFocus = () => {
    setSearchFocused(true)
  }
  const onLostFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setTimeout(() => {
        setSearchFocused(false)
      }, 500)
    }
  }
  const onTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value ? e.target.value : ''
    setSearchText(text)
    autosuggest({ variables: { keyword: text } })
    setShowPopup(text.length > 0)

    if (text.length > 0) {
      if (MIXPANEL_ENABLED) {
        mixpanel.track('search_content', {
          keyword: text,
        })
      }
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      router.push({ pathname: '/search', query: { keyword: searchText } })
    }
  }

  const { solutions, categories, events, companies } = data?.autosuggest || {
    solutions: [],
    categories: [],
    events: [],
    companies: [],
  }
  const hasAutosuggest = events.length > 0 || categories.length > 0 || solutions.length > 0 || companies.length > 0

  return (
    <div className={cn('relative', className)} onFocus={onFocus} onBlur={onLostFocus}>
      <div
        className={cn(
          'z-20 relative rounded-[1.875rem] border-2 bg-white border-solid flex flex-row items-center pl-[1.3125rem] pr-[0.75rem] h-[3.875rem]',
          {
            'border-turquoise': searchFocused,
            'shadow-md': searchFocused,
            'border-white': !searchFocused,
          },
          { 'text-darkgray2': THEME === 'ilta' },
          { 'text-textdark': THEME === 'legaltech' },
        )}
        {...props}
      >
        <IonIcon name="search" className="mr-2 w-[0.9225rem] h-[0.9225rem]" />
        <input
          autoFocus
          type="text"
          className={cn(
            'focus:outline-none grow text-[14px] mr-[14px] leading-[42px]',
            { 'placeholder-darkgray2 font-secondary font-normal': THEME === 'ilta' },
            { 'placeholder-textdark font-primary font-medium': THEME === 'legaltech' },
          )}
          placeholder="Search by keyword, product name, etc."
          onChange={onTextChanged}
          onKeyPress={handleKeyPress}
        />
        <Link href={{ pathname: '/search', query: { keyword: searchText } }}>
          {searchText === '' ? (
            <Tooltip title="Search all results" placement="right">
              <div
                className={cn(
                  'rounded-full flex flex-none bg-turquoise w-[2.5rem] h-[2.5rem] cursor-pointer items-center justify-center',
                  { 'text-white': THEME === 'ilta' },
                )}
              >
                <IonIcon
                  className={cn('mr-0 !w-[20px] !h-[20px]', {
                    'text-white': THEME === 'ilta',
                  })}
                  name="arrow-forward-sharp"
                />
              </div>
            </Tooltip>
          ) : (
            <div className="rounded-full flex flex-none bg-turquoise w-[2.5rem] h-[2.5rem] cursor-pointer items-center justify-center">
              <IonIcon
                className={cn('mr-0 !w-[20px] !h-[20px]', {
                  'text-white': THEME === 'ilta',
                })}
                name="arrow-forward-sharp"
              />
            </div>
          )}
        </Link>
      </div>

      <div
        className={cn(
          'absolute z-10 top-0 rounded-[1.875rem] bg-white shadow-md w-full max-h-[20rem] pt-[4.875rem] pb-8 overflow-hidden flex flex-col',
          {
            hidden: !(showPopup && hasAutosuggest && searchFocused),
          },
        )}
      >
        <div className="w-full flex-1 overflow-y-scroll flex flex-col items-center">
          <div className="w-full">
            <Link
              href={{ pathname: '/search', query: { keyword: searchText } }}
              className="w-full bg-white hover:bg-gray-400 cursor-pointer px-8 text-textdark font-primary font-normal text-[.875rem] leading-7"
            >
              Search for "{searchText}"
            </Link>
            <div className="w-full px-8 mt-3 mb-2 h-px">
              <div className="w-full bg-navy opacity-20 h-[1px]" />
            </div>
          </div>

          {categories.length > 0 && (
            <>
              <div className="w-full">
                <p className="w-full px-8 font-primary font-normal text-darkgray text-[.8125rem] leading-8">
                  Categories
                </p>
                {categories.map((data, idx: number) => (
                  <CategoryAutosuggestItem data={data} search={searchText} key={idx} />
                ))}
              </div>
              <div className="w-full px-8 mt-3 mb-2 h-px">
                <div className="w-full bg-navy opacity-20 h-[1px]" />
              </div>
            </>
          )}

          {solutions.length > 0 && (
            <>
              <div className="w-full">
                <p className="w-full px-8 font-primary font-normal text-darkgray text-[.8125rem] leading-8">
                  Solutions
                </p>
                {solutions.map((data, idx: number) => (
                  <ProductAutosuggestItem data={data} search={searchText} key={idx} />
                ))}
              </div>
              <div className="w-full px-8 mt-3 mb-2 h-px">
                <div className="w-full bg-navy opacity-20 h-[1px]" />
              </div>
            </>
          )}

          {companies.length > 0 && (
            <>
              <div className="w-full">
                <p className="w-full px-8 font-primary font-normal text-darkgray text-[.8125rem] leading-8">
                  Companies
                </p>
                {companies.map((data, idx: number) => (
                  <CompanyAutosuggestItem data={data} search={searchText} key={idx} />
                ))}
              </div>
              <div className="w-full px-8 mt-3 mb-2 h-px">
                <div className="w-full bg-navy opacity-20 h-[1px]" />
              </div>
            </>
          )}

          <ThemeOnly theme="legaltech">
            {events.length > 0 && (
              <>
                <div className="w-full">
                  <p className="w-full px-8 font-primary font-normal text-darkgray text-[.8125rem] leading-8">Events</p>
                  {events.map((data, idx: number) => (
                    <EventAutosuggestItem data={data} search={searchText} key={idx} />
                  ))}
                </div>
                <div className="w-full px-8 mt-3 mb-2 h-px">
                  <div className="w-full bg-navy opacity-20 h-[1px]" />
                </div>
              </>
            )}
          </ThemeOnly>
        </div>
      </div>
    </div>
  )
}
